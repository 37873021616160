import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        slug: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
        shortDescription: PropTypes.string,
        created_at: PropTypes.string,
      }),
    }),
  ).isRequired,
  render: PropTypes.func.isRequired,
};

const PostIterator = ({ posts, render }) => (
  <Fragment>
    { posts && posts.map(({ node: post }) => {
      const path = `news-and-events/${post.slug}`;
      const event = post.type === 'Event';
      return render(post, path, event);
    }) }
  </Fragment>
);

PostIterator.propTypes = propTypes;

export default PostIterator;
