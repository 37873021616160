import React from 'react';
import { string, bool } from 'prop-types';
import { Link } from 'gatsby';

import NewsIcon from '../../images/news-icon.png';
import CalendarIcon from '../../images/calendar-icon.png';

const propTypes = {
  title: string.isRequired,
  text: string.isRequired,
  to: string.isRequired,
  event: bool,
};

const defaultProps = {
  event: false,
};

const getIcon = (event) => {
  let icon = NewsIcon;
  if (event) {
    icon = CalendarIcon;
  }
  return (
    <img src={icon} alt="ZooPharm" />
  );
};

const NewsEvents = ({
  title, text, event, to,
}) => (
  <div>
    <Link to={to}>
      <h4>
        {getIcon(event)}
        {title}
      </h4>
    </Link>
    <p>{text}</p>
  </div>
);

NewsEvents.propTypes = propTypes;
NewsEvents.defaultProps = defaultProps;
export default NewsEvents;
